.ce-code__textarea {
  background: theme('colors.neutral-content') !important;
  color: theme('colors.base-content') !important;
  border-radius: 8px !important;
  @apply scrollbar-thumb-primary/40 scrollbar-track-primary-content scrollbar-thin scrollbar-track-rounded-full scrollbar-thumb-rounded-full;
}
.inline-code {
  background: theme('colors.neutral-content') !important;
  color: theme('colors.base-content') !important;
}
