.ce-popover__container {
  @apply text-base-content bg-base-100;
}

.ce-popover {
  --border-radius: 6px;
  --width: 200px;
  --max-height: 270px;
  --padding: 6px;
  --offset-from-target: 8px;
  --color-border: theme('colors.neutral-content');
  --color-shadow: rgba(13, 20, 33, 0.1);
  --color-background: theme('colors.error');
  --color-text-primary: theme('colors.base-content');
  --color-text-secondary: theme('colors.base-100');
  --color-border-icon: theme('colors.error');
  --color-border-icon-disabled: theme('colors.warning');
  --color-text-icon-active: theme('colors.primary');
  --color-background-icon-active: theme('colors.primary-content');
  --color-background-item-focus: theme('colors.primary-content');
  --color-shadow-item-focus: theme('colors.primary-content');
  --color-background-item-hover: theme('colors.primary-content');
  --color-background-item-confirm: theme('colors.error-content');
  --color-background-item-confirm-hover: theme('colors.error');
  --popover-top: calc(100% + var(--offset-from-target));
  --popover-left: 0;
  --nested-popover-overlap: 4px;
  --icon-size: 20px;
  --item-padding: 3px;
  --item-height: calc(var(--icon-size) + 2 * var(--item-padding));
}

.codex-editor ::selection {
  color: theme('colors.accent') !important;
  background: theme('colors.accent-content') !important;
}

.ce-inline-tool:hover {
  background: theme('colors.primary-content');
}
.ce-inline-toolbar {
  --color-background-icon-active: theme('colors.base-200');
  --color-text-icon-active: theme('colors.primary');
  --color-text-primary: theme('colors.base-content');
  position: absolute;
  visibility: hidden;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
  will-change: opacity, left, top;
  top: 0;
  left: 0;
  opacity: 1;
  visibility: visible;
}

.ce-block--selected .ce-block__content {
  background: theme('colors.accent-content');
}

.ce-inline-tool-input,
.cdx-search-field {
  background: theme('colors.base-100');
  border-color: theme('colors.primary');
}

.ce-popover__items {
  @apply scrollbar-thumb-primary/40 scrollbar-track-primary-content scrollbar-thin scrollbar-track-rounded-full scrollbar-thumb-rounded-full;
}

.cdx-input {
  background: theme('colors.base-100');
  border-color: theme('colors.neutral-content');
}

.ce-toolbar__plus,
.ce-toolbar__settings-btn {
  color: theme('colors.neutral');
}

.ce-toolbar__plus:hover,
.ce-toolbar__settings-btn:hover {
  background: theme('colors.primary-content');
  color: theme('colors.primary');
}
.ce-popover-item:first-child .ce-popover-item__icon--tool svg[width='12'] {
  width: 14px;
  height: 14px;
}

html[data-theme='light']
  .ce-popover-item:first-child
  .ce-popover-item__icon--tool
  svg[width='12'] {
  color: theme('colors.base-100');
}

.ce-block__content {
  width: 100% !important;
  max-width: none;
}

.ce-toolbar__content {
  position: relative;
  max-width: none;
  width: 100% !important;
}

@media (max-width: 650px) {
  .ce-toolbar__plus,
  .ce-toolbar__settings-btn {
    background-color: theme('colors.primary-content');
    border: none;
    width: 24px;
    height: 24px;
  }
}

@media (min-width: 651px) {
  .codex-editor--narrow .ce-toolbar__actions {
    left: -60px;
    width: fit-content;
  }
  .codex-editor--narrow .codex-editor__redactor {
    margin-right: 0px;
  }
}

.cdx-settings-button {
  color: theme('colors.base-content');
}
