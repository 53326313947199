.bookHtmlDisplay h1,
.bookHtmlDisplay h2,
.bookHtmlDisplay h3,
.bookHtmlDisplay h4,
.bookHtmlDisplay h5 {
  @apply font-500 text-primary;
}

.bookHtmlDisplay h1 {
  @apply text-24 mb-3;
}
.bookHtmlDisplay h2 {
  @apply text-20 mt-8 mb-2.5;
}
.bookHtmlDisplay h3,
h4 {
  @apply text-18 mt-7 mb-2;
}

.bookHtmlDisplay p {
  @apply indent-4 md:indent-8 text-16 font-400 font-rubik text-base-content w-full text-justify py-3;
}
.bookHtmlDisplay hr {
  @apply content-none border border-neutral opacity-40 my-5;
}
.bookHtmlDisplay img {
  @apply mx-auto mt-6;
}
.bookHtmlDisplay ol,
.defaultHtmlDisplay ol {
  @apply pl-8 md:pl-12 font-rubik text-16 space-y-2 text-justify text-base-content;
}

.bookHtmlDisplay ol ol,
.defaultHtmlDisplay ol ol {
  list-style-type: lower-alpha !important;
}

.bookHtmlDisplay ol ol ol,
.defaultHtmlDisplay ol ol ol {
  list-style-type: lower-roman !important;
}

.bookHtmlDisplay ul {
  @apply pl-8 md:pl-12 font-rubik text-16 items-center space-y-2 text-justify;
  list-style: url(/public/images/star.png);
}

.bookHtmlDisplay ul li,
.bookHtmlDisplay ol li {
  color: var(--base-content) !important;
}

.defaultHtmlDisplay {
  @apply text-base-content;
}

.htmlDisplay figcaption {
  @apply text-14 text-center;
}

.htmlDisplay .todo-list {
  @apply list-none list-inside inset-0 pl-0;
}

.htmlDisplay input[type='checkbox'] {
  @apply bg-primary/60 disabled:bg-primary/60 border-primary;
}

.videoHtmlDisplay {
  @apply aspect-video w-full;
}

.htmlDisplay pre {
  @apply bg-neutral-content w-full px-6 py-1.5 rounded-lg;
}

.htmlDisplay pre code {
  @apply bg-neutral-content;
}

.htmlDisplay .table,
.htmlDisplay .image {
  @apply m-auto;
}

.htmlDisplay .image-style-side {
  @apply float-right pl-2 max-w-[50%];
}

.htmlDisplay table,
.htmlDisplay tbody,
.htmlDisplay tr,
.htmlDisplay td {
  @apply border border-inherit bg-inherit rounded-none p-1.5;
}
