h1.ce-header {
  display: block;
  font-size: 2.5em;
  line-height: 1em;
  width: fit-content;
  font-weight: bold;
}

h2.ce-header {
  display: block;
  font-size: 2em;
  font-weight: bold;
}

h3.ce-header {
  display: block;
  font-size: 1.75em;
  font-weight: bold;
}

h4.ce-header {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
}

h5.ce-header {
  display: block;
  font-size: 1.25em;
  font-weight: bold;
}

h6.ce-header {
  display: block;
  font-size: 1em;
  font-weight: bold;
}

h6.ce-header .cdx-underline,
h5.ce-header .cdx-underline {
  text-decoration: underline;
  text-decoration-color: theme('colors.secondary/50');
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
}
