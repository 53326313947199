.tc-wrap {
  --color-background: theme('colors.base-100') !important;
  --color-text-secondary: theme('colors.base-content') !important;
  --color-border: theme('colors.neutral-content');
  --cell-size: 34px;
  --toolbox-icon-size: 18px;
  --toolbox-padding: 6px;
  --toolbox-aiming-field-size: calc(
    var(--toolbox-icon-size) + var(--toolbox-padding) * 2
  );
}

.tc-popover {
  --color-border: theme('colors.neutral') !important;
  --color-background: theme('colors.base-100') !important;
  --color-background-hover: theme('colors.primary-content') !important;
  --color-background-confirm: theme('colors.error-content') !important;
  --color-background-confirm-hover: theme('colors.error') !important;
  --color-text-confirm: theme('colors.base-content') !important;
}
